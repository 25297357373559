// Use Imperial Blue as the primary colour
$theme-colors: (
  "primary": #003e74,
);

$headings-font-family: "Merriweather", serif;
$headings-font-weight: 700;

@import "~bootstrap/scss/bootstrap";

.icrpc-title {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}

div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
    padding: 0.5rem 0rem 1rem 0rem;
  }
}

footer {
  padding: 1rem 0.5rem;
  color: #ffffff;
  // Imperial Navy as the background
  background-color: #002147;
}

.form-stage-indicator {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  display: flex;
  justify-content: space-between;

  position: relative;

  .form-stage-indicator-element {
    display: flex;
    flex-grow: 1;
    justify-content: center;

    position: relative;

    .form-stage-indicator-element-bar {
      position: absolute;

      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      z-index: 10;

      border-top-style: solid;
      border-top-width: 0.25rem;
    }

    .form-stage-indicator-element-circle {
      display: flex;
      justify-content: center;
      align-content: center;

      height: 2rem;
      width: 2rem;
      line-height: 1.9rem;
      z-index: 20;

      border-radius: 50%;
    }
  }
}
